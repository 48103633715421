import React from "react";
import './OurDifferences.css'
import './OurDifferences-Movil.css'

export const OurDifferences = (prop) =>{

    const content = prop.content;

    const title = content.title
    const desc = content.desc

    return(
        <section className="our-differnces Section">
            <div className="max-width-container blue OURD">
                <div className="od-content">
                    <h2 className="od-content-title general-title-h2">{title}</h2>
                    <p className="od-content-p">{desc}</p>
                </div>
                
            </div>
            <div className=" max-width-container od-img">                     
                    <img src="/resources/Pancho/panchaIcon.svg" alt=""/>                    
                </div>
        </section>
    )
}