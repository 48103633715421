import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import contentEn from './Temps/contentEn.json';
import contentEs from './Temps/ContentEs.json';


/** This code function is change the web language**/ 


// the translations
const resources = {
  en: {
    translation: contentEn
  },
  es: {
    translation: contentEs
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    returnObjects: true,
    resources,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;