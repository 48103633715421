import React, { useEffect, useState } from "react";
import { NavLink } from '../NavLink/NavLink';
import './Menu.css'
import './Menu-movil.css'
import { ChangeLanguage } from "../ChangeLanguaje/ChangeLanguage";



export const Menu= ({isHeader, isMovil, enable, content, enableAction, languageContent2}) =>{
    
    const [enable_, setEnable]= useState(enable)
    const [isActive, setisActive]= useState('I')
    const [enableDropdown, setEnableDropdown]= useState(false)

    useEffect(() => {
        setEnable(enable)
        if(enable) setEnableDropdown(false)
    }, [enable]);


    const enableDro = () =>{
        setEnableDropdown(!enableDropdown)
    }

    console.log(languageContent2);
    return(
        <nav className={`menu ${isMovil? 'isMovil': ''} ${enable_? '': 'disable'}`}>
            <ul className={`${isHeader? 'isHeader': 'noHeader'}`}>
                <li className={`iconClose ${enable_&&isMovil? '': 'disable'}`} ><img onClick={enableAction} src={'/resources/Vectores/closeIcon.svg'} alt="Menu Close Icon"/></li>
                <li onClick={()=>{setisActive('I')}} ><NavLink to='/' classnl={isActive==='I'?'activo':''}>{content.home}</NavLink></li>
                <li onClick={()=>{setisActive('S')}} ><NavLink to='/services' classnl={isActive==='S'?'activo':''}>{content.services}</NavLink> </li>

                {/* <li className= {`dropdown ${isActive==='C' || isActive==='K'?'activo':''}`} onClick={isMovil? enableDro : ()=>{}}> */}
                <li className= {`dropdown`} onClick={isMovil? enableDro : ()=>{}}>
                    { isMovil? '' : 
                        <ul className="internal-nav">
                            <li onClick={()=>{setisActive('C')}} ><NavLink to='/about-us-company' classnl={isActive==='C'?'activo':''}>{content.Us.company}</NavLink></li>
                            <li onClick={()=>{setisActive('K')}} ><NavLink to='/KnowUs' classnl={isActive==='K'?'activo':''}>{content.Us.comunity}</NavLink></li>
                        </ul>
                    }
                <span className="know-us">{content.Us.title}</span>                 
                </li>
                <li className={enableDropdown? 'drpdwnLi': 'disable'} onClick={()=>{setisActive('C')}}><NavLink to='/about-us-company' classnl={isActive==='C'?'activo':''}>{content.Us.company}</NavLink></li>
                <li className={enableDropdown? 'drpdwnLi': 'disable'} onClick={()=>{setisActive('K')}}><NavLink to='/KnowUs' classnl={isActive==='K'?'activo':''}>{content.Us.comunity}</NavLink></li>

                <li  ><NavLink to='https://www.waduaverso.wadua.com.co/'>Waduaverso</NavLink> </li>
                <li onClick={()=>{setisActive('Su')}} ><NavLink to='/Survey' classnl={isActive==='Su'?'activo':''}>{content.survey}</NavLink></li> {/* Encuesta */}
                <li className={`last-li ${enable_&&isMovil? '': 'disable'}`} ><ChangeLanguage lan={languageContent2}></ChangeLanguage></li> 
                <li  ><NavLink to='https://www.waduaverso.wadua.com.co/webinars'>Webinar</NavLink> </li>
            </ul>
            <div className={`logo-menu ${enable_&&isMovil? '': 'disable'}`}>
                <img src="/resources/Vectores/Wadua.svg" alt="Logo Wadua"/>
            </div>
        </nav>
    )
}