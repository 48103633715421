import React from "react";
import './Experts.css'
import './Experts-Movil.css'

import { Slider } from "../../Carousel2_test/Carousel2";

// *components
import { Button } from "../../Button/Button";
import { NavLink } from "../../NavLink/NavLink";

const programsIcons = [
    {
        id: 1,
        img: '/resources/Vectores/Logo Power BI.svg',
        des: 'Power BI'
    },
    {
        id: 2,
        img: '/resources/Vectores/Logo Qlik.svg',
        des: 'Qlik Sense'
    }
]

export const Experts = (prop) => {


    const contentS3 = prop.contentS3

    const title = contentS3.title
    const text = contentS3.desc
    const btnText = contentS3.btn
    const title2 = contentS3.title2
    const programsIcons = contentS3.programsIcons

    return (
        <section id="Section3" className="Section" >
            <div className="max-width-container white">
                <div className="S3-content">
                    <h2 className="general-title-h2">{title}</h2>
                    <p>{text}</p>
                </div>
                <div className="S3-image">
                    <h2 className="S3-carousel-title general-title-h2">{title2}</h2>
                    <div className="carousel-container">
                        <Slider members={programsIcons} cant={1}>
                            {(content) => (
                                <div className={`carousel-image-container carousel-her-cont`}>
                                    <div className="carousel-image-container-desc">
                                        <p className="experts-desc">{content.des}</p>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}