import React, {useState} from "react";
import './OurTeam.css'
import './OurTeam-Movil.css';

import { Slider } from "../Carousel2_test/Carousel2.js";

export const OurTeam = ({title, teamMembers}) =>{

    const [members, setMembers] = useState([]);
    // const [isVisible, setisVisible] = useState(false);

    // const teamMembers = require('../../Temps/teamMemersC.json')

    function bringMembers (data){
            setMembers(data.members)
            // setisVisible(true) 
    }

    // function disableMembers (){
    //     setisVisible(false)
    // }

    return(
        <>
            <div className="slider-services">
                <h2 className="general-title-h2 title-s-s">{title}</h2>
                {/* <Slider  slider_type={true} members={teamMembers.teamMemersC} visibleItemsNumber={2} isObject={false} disable ={isVisible? 'disable': ''} cant= {1} gap='20px'> */}
                <div>
                    <Slider  
                        slider_type={true} 
                        members={teamMembers} 
                        visibleItemsNumber={2} 
                        isObject={false}  
                        cant= {1} gap='20px'
                        colorDot= {false}
                        lateralControls={true}
                        enableDots={false}
                        colorArrow= 'white'
                    >
                        {(data) => (                      
                            // <div className={`category-teamMembers ${isVisible? 'disable': ''}`} >
                            <div className={`category-teamMembers`} >
                                {bringMembers(data)} 
                                {/* <button className= {`category-teamMembers-img ${data.title}`} onClick={()=>bringMembers(data)}>  */}
                                <div className= {`category-teamMembers-img`}> 
                                    <img  className="img-category img-team" src={data.img} alt="icon-Category"/>
                                </div>                               
                                <h3 className="category-teamMembers-title title-team general-title-h3">{data.title}</h3>
                                <span></span>
                            </div>
                        )}
                    </Slider> 
                </div>
            </div>
            {/* <button className={`carousel-close ${isVisible? '' : 'disable'}`} onClick= {disableMembers}>
                <img src="/resources/Vectores/arrow2.svg" alt="arrow"/>
            </button> */}
            {/* <div className={`carousel-team ${isVisible? '' : 'disable'}`}> */}
            <div className={`carousel-team`}>
                <Slider  
                    slider_type={true} 
                    members={members} 
                    visibleItemsNumber={2}
                    colorDot= {false}
                    lateralControls={true}
                    difLateral={true}
                >
                    {(members) => (
                        <div className="content-members">
                            <div className= {`category-teamMembers-img member-ctmi`}> 
                                <img
                                    id={members.id}
                                    src={members.img}
                                    alt={`${members.name} pictures`}
                                    className="image img-team img-team-member"
                                />
                            </div>
                            <div className="content-title">
                                <h3 className="category-teamMembers-title title-team">{members.name.split(' ')[0]}</h3>
                                <h3 className="category-teamMembers-title title-team">{members.name.split(' ')[1]+' '+ (members.name.split(' ')[2]?members.name.split(' ')[2]:'')}</h3>
                            </div>
                            <span className="career">{members.career}</span>
                            <div className="linkdnIcon">
                                <a href={members.likdn} target="_blank" rel="noreferrer"><img src="/resources/Vectores/lkin2.svg" alt="LinkedIn Icon"/></a>
                            </div>
                        </div>
                    )}
                </Slider>                
            </div>
            

        </>
    )
}