import React from "react";
import './Dots.css'

export const Dots = ({ items, active, visibleI, onClick, colorDot, enable=true }) => {
    const prueba = (active + visibleI)-1
    // console.log(items, "lehgt_________________________________________") 
    return (
        <ul className={`dotList ${enable?'':'disableDots'}`}>

        {Array.from(Array(items).keys()).map((dot) => (
            <><li
                key={dot}
                className={ `dot 
                ${
                    active + visibleI >= items ? 
                    (active <= dot || prueba<=dot)? '': colorDot? 'active': 'active2'
                    :active <= dot && prueba>=dot? '': colorDot? 'active': 'active2'
                }`}
                onClick={() => onClick(dot)}

            />
            {/* { console.log(active + ' ' + prueba + ' ' + dot , "active + prueba + dot") }
            { console.log(active + visibleI >= items ? '1': '2')}
            { console.log(active + visibleI >= items ? 
                    active >dot || prueba<dot? 'no1': 'active1'
                    :active <= dot && prueba>dot? 'no2': 'active2')} */}
            
            </>
        ))}
        </ul>
    );
}

 