import React from "react";
import './SectionMision.css' 
import './SectionMision-Movil.css';

//*Components
import { Button } from "../../Button/Button";

export const Mision = (prop) =>{

    const contentS2 = prop.contentS2;

    const title = contentS2.title
    const text = contentS2.desc
    const phrase = contentS2.phrase
    const btnText = contentS2.btn
    const tempText = contentS2.desc2

    return(
        <section id="Section2 " className="Section">
            <div className="max-width-container green">
                <div className="S2-image_mision"> 
                </div>
                <div className="S2-content MisionContent">
                    <div className="S2-content-info">
                        <h2 className="general-title-h2 mision_title">{title}</h2> 
                        <p>{text}</p>
                        <p className="phrase">  {phrase}</p>
                    </div>                
                </div>
            </div>
        </section>
    )
}