import React, {useEffect, useState} from "react";
import { useSwipeable } from "react-swipeable";
import './Carousel2.css'
import './CarouselCard/Carouselcard.css'
import './CarouselKnowUs/CarouselKnowUs.css'
import './CarouselKnowUs/CarouselKnowUs_installs.css'
import { Dots } from "./Dots/Dots";
import { useWindowSize } from "../hooks/windowSize";
import { NextButton } from "./NextButton/NextButton,";
import { PrevButton } from "./PrevButton/PrevButton";


export const Slider = (
    { members,children, isObject = false, disable, cant =1, slider_type , preuba,  gap = '20px',colorDot=true
    ,lateralControls=false, enableDots=true, difLateral=false, colorArrow='blue'
}) => {


    const [visibleItemsNumber, setVisibleItemsNumber] = useState(cant);
    const [start, setStart] = useState(0); //Where the carousel begin

    const screenWidth = useWindowSize();  

    useEffect(() => {        
        if (slider_type === true){
            setVisibleItemsNumber(1)
        }        
        else if (screenWidth < 769 ) {
            setVisibleItemsNumber(cant)

        } else if (screenWidth < 1100) {
            setVisibleItemsNumber(cant+1)
        }
        else if (screenWidth < 1920){
            setVisibleItemsNumber(cant+2)
        }
        else {
            // setVisibleItemsNumber(cant+3)
            setVisibleItemsNumber(cant+2)
        }
    }, [screenWidth]);


    const isControlsVisible = members.length > visibleItemsNumber; //If the items of a carousel are more than the visible items, then the constrols don't show
    
    const visibleItems = isControlsVisible
        ? members.concat(members.slice(0, visibleItemsNumber)).slice(start, start + visibleItemsNumber)
        : members;


    const handlers = useSwipeable({
        onSwipedLeft: () => setStart(start + visibleItemsNumber >= members.length ? 0 : start + visibleItemsNumber),
        onSwipedRight: () => setStart(start - visibleItemsNumber >= 0 ? start - visibleItemsNumber : members.length - 1)
    });


    const onNextClick = () => {
        setStart(start + visibleItemsNumber >= members.length ? 0 : start + visibleItemsNumber);
    };

    const onPrevClick = () => {
        setStart(start - visibleItemsNumber >= 0 ? start - visibleItemsNumber : members.length - visibleItemsNumber);
    };

    const styles= {
        'gap': gap
    }

    return (
        <div {...handlers} className={`carousel2 ${disable}`} >
            <div className={'slides'}>
            <ul className={'list'} style ={styles}>
                {isObject ? children ? children(1) : null :
                visibleItems.map((slide) =>
                    children ? children(slide) : null )
                }
            </ul>
            </div>
            <div className={'dotsControls'}>
            {/* {isControlsVisible && ( */}
                <>
                <PrevButton color={colorArrow} onClick={onPrevClick} className={`navButtons navBtnArrow ${lateralControls? difLateral?'lateralPrevBtn2':'lateralPrevBtn':''}`}/>
                <Dots
                    items={members.length}
                    active={start}
                    visibleI={visibleItemsNumber}
                    onClick={(active) => setStart(active)}
                    colorDot={colorDot}
                    enable={enableDots}
                />
                <NextButton color={colorArrow} onClick={onNextClick} className={`navButtons navBtnArrow ${lateralControls?difLateral?'lateralPostBtn2':'lateralPostBtn':''}`} />
                </>
                 {/* )} */}
            </div>
        </div>
    );

}
