import React from "react";
import './Section3.css'
import './Section3-Movil.css'

import { Slider } from "../../Carousel2_test/Carousel2";

// *components
import { Button } from "../../Button/Button";
import { NavLink } from "../../NavLink/NavLink";

const programsIcons =[
    {
        id: 1,
        img: '/resources/Vectores/Logo Power BI.svg',
        des : 'Power BI'
    },
    {
        id: 2,
        img: '/resources/Vectores/Logo Qlik.svg',
        des : 'Qlik Sense'
    },
    {
        id: 3,
        img: '/resources/Vectores/looker.svg',
        des : 'Looker'
    },
    {
        id: 4,
        img: '/resources/Vectores/spotfire.svg',
        des : 'Spotfire'
    },
    {
        id: 5,
        img: '/resources/Vectores/Logo Qlik.svg',
        des : 'QlikView'
    },
    {
        id: 6,
        img: '/resources/Vectores/aws.svg',
        des : 'AWS'
    },
    {
        id: 7,
        img: '/resources/Vectores/kubernetes.svg',
        des : 'Kubernetes'
    },
    {
        id: 8,
        img: '/resources/Vectores/CI-CD.svg',
        des : 'CI/CD'
    },
    {
        id: 9,
        img: '/resources/Vectores/dev-ops.svg',
        des : 'DevOps'
    },
    {
        id: 10,
        img: '/resources/Vectores/react.svg',
        des : 'React'
    },
    {
        id: 11,
        img: '/resources/Vectores/fastapi.svg',
        des : 'FastAPI'
    },
    {
        id: 12,
        img: '/resources/Vectores/Logo Phyton.svg',
        des : 'Python'
    },
    {
        id: 13,
        img: '/resources/Vectores/javascript.svg',
        des : 'JavaScript'
    },
    {
        id: 14,
        img: '/resources/Vectores/git.svg',
        des : 'GIT'
    }
]

export const Section3 = (prop) =>{    


    const contentS3 = prop.contentS3

    const title = contentS3.title
    const text = contentS3.desc
    const btnText = contentS3.btn
    const title2 = contentS3.title2

    return(
        <section id="Section3" className="Section" >
            <div className="max-width-container white">
                <div className="S3-content"> 
                        <h2 className="general-title-h2">{title}</h2> 
                        <p>{text}</p>              
                    <NavLink className="S3-Btn" to="/services" classnl = 'S3-Btn'>                    
                        <Button textBtn={btnText} bckColorClase='' onIcon={false}/>
                    </NavLink>
                </div>
                <div className="S3-image"> 
                    <h2 className="S3-carousel-title general-title-h2">{title2}</h2>
                    <div className="carousel-container">
                        <Slider  members={programsIcons} cant={1}>
                            {(content) => (
                                <div className={`carousel-image-container carousel-her-cont`}>
                                    <div className="carousel-image-container-img">
                                        <img className="image-container-img" src={content.img} alt={content.img} />                                     
                                    </div>
                                    <div className="carousel-image-container-desc">
                                        <p>{content.des}</p>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}