import React from "react";
import './Button.css'

export const Button = ({bckColorClase, textBtn, onIcon, event}) =>{
    return(
        <>
            <div className={`inconP-Btn ${onIcon?'iconPanchoActive':'iconPanchoNone'}`}>
                <img src="/resources/panchoColorIcon.svg" alt="SVG Pancho icon color"/>
            </div>
            <button className= {`buttonBorder ${bckColorClase}`} onClick={event}>
                <div className="borderAnimationBtn"></div>
                <div className="txtBtn"><span>{textBtn}</span></div>
            </button>
        </>        
    )
}