import React from "react";
import './Globe.css'

//*Components

export const Globe = ({idCheck, eventCheck, text,imgFront, imgBack,imgST }) => {

    return (
                <label>
                    <input type="checkbox" id={idCheck} onClick={eventCheck}></input>
                    <div className="o-flip-card">
                        <div className="o-front">
                            <img className="img_sticker" src={`/resources/Vectores/${imgST}`} alt=""/>
                            <img className="o-background" src={`/resources/Vectores/${imgFront}`} alt=""/>
                            
                        </div>
                        <div className="o-back">
                            <p>{text}</p>
                            <img className="o-background" src={`/resources/Vectores/${imgBack}`} alt=""/>
                        </div>
                    </div>
                </label>
    )
}