import React from "react";
import { useEffect, useState } from "react";
import { Section1 } from "../Components/ServicesPage/Section1/Section1";
import { Section2 } from "../Components/ServicesPage/Section2/Section2";
import { Section2_carousel } from "../Components/ServicesPage/Section2/Section2_carousel";
import { OurDifferences } from "../Components/ServicesPage/OurDifferences/OurDifferences";
import { OurServices } from "../Components/ServicesPage/OurServices/OurServices";
import { OurValues } from "../Components/ServicesPage/OurValues/OurValues";
import { useWindowSize } from "../Components/hooks/windowSize";


export const Services = ({ content }) => {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    //* Hooks
    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version

    //* read windows width size and  set hooks true or false
    const screenWidth = useWindowSize();
    
    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 1024) {
            setisVisible(false)
            // allowScroll()
        } else if (screenWidth <= 1023) {
            setisVisible(true)
        }
    }, [screenWidth]);

    return (
        <>
            <Section1 contents1={content.title}/>
            <Section2 isMovil={isVisible} contents2={content.can_help}/>
            <Section2_carousel isMovil={isVisible} contents2={content.can_help}/>
            <OurServices content={content.our_services} isEng={false}></OurServices>
            <OurValues content={content.value_principles} isEng={false}></OurValues>
            <OurDifferences content={content.our_diference} isEng={false}></OurDifferences>
        </>
    )
}