import React from "react";
import './Section5.css';

import {NavLink} from '../../NavLink/NavLink'



export const Section5 = () => {
    return (
        <section className=" o-be-part Section">
            <div className="o-img">
                <NavLink className="o-img-link" href='/Knowus' classnl='o-img-link'>
                    <img src="/resources/Pancho/Pancho.png" alt="" />
                </NavLink>
            </div>
        </section>
    )
}