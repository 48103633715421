import React from "react";
import i18n from "../../i18n";
import './Changelanguage.css'

export const ChangeLanguage = ({lan}) =>{
    
    //* change language
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
      }
    return(
        <>
            {/* <p className="language">{lan.title}</p> */}
            <button className="language-btn" > 
                <img 
                    onClick={() => changeLanguage(lan.avr)}
                    className="language-img" src={lan.language} alt='language' />
            </button>
        </>
    )
}