import React from "react";
// import { useState, useEffect } from "react";
// import { useWindowSize } from "../../../hooks/windowSize";
import './Card_base.css'

export const Card_base = ({ id, tittle, text, text2, color, img }) => {

    const styles = { 'backgroundColor': color }

    return (
        <div className="o-card_base">
            <div className="o-card-cont">
                <div className="o-card-cont-left">
                    <img src="/resources/Pancho/pancho-22.svg" alt="" />
                </div>
                <div className="o-card-text">
                    <h3>{text}</h3>
                </div>
            </div>
            <div className="o-card-tittle" style={styles}>
                <h3>{tittle}</h3>
            </div>

        </div>

    )
}