import React from "react";
import { SurveyFrame } from "../Components/Survey/SurveyFrame";


export const Survey = () => {

    return (
        <>
           <SurveyFrame></SurveyFrame>
        </>
    )
}