import React from "react";
import "./Socialmedia.css";

export const Socialmedia = () => {
    return (
        <>
        <div className="socialButton">
            <a
            className="socialLogo"
            href="https://www.linkedin.com/company/wadua-analytics/"
            target='_blank'
            rel=" noopener noreferrer"
            >
            <img src="/resources/Vectores/lkin2_blue.svg" alt="SVG linkedin icon" />
            </a>
        </div>
        <div className="socialButton">
            <a className="socialLogo" href="https://www.instagram.com/wadua_analytics/"
            target='_blank' rel=" noopener noreferrer">
            <img src="/resources/Vectores/instagram2.svg" alt="SVG instagram icon" />
            </a>
        </div>
        <div className="socialButton">
            <a className="socialLogo" href="https://www.tiktok.com/@wadua_analytics?lang=es" 
            target='_blank'
            rel=" noopener noreferrer">
            <img src="/resources/Vectores/tiktok2.svg" alt="SVG tiktok icon" />
            </a>
        </div>
        </>
    );
};
