import React from "react";
import { Card } from "./Card/Card";
import './Section2.css'

export const Section2 = ({isMovil,contents2}) => {
    
    const arrCont = contents2.cards

    return (
        <div className={`Section o-serv-s2 ${isMovil? 'disable': ''}`}>
            <div className="max-width-container white OSS2">
                {/* <Card_base tittle={contents2.card_base.title} text={contents2.card_base.text}/> */}
                {
                    arrCont.map(
                        (carta) => (
                            <Card id={carta.id} tittle={carta.title} text={carta.text} text2={carta.text2} img={carta.img} color={carta.color}/>
                        )
                    )
                }
            </div>
        </div>
    )
}