import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { json } from "react-router-dom";

import './Banner.css'

export const Banner = ({ content, serv }) => {

    const text = content.title;
    const autoplaySpeed = serv ? 9000 : 4000;
    const [images, setImages] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            let selectedImages = content.full;

            if(screenWidth < 600){
                selectedImages = content.small;
            } else if(screenWidth >= 600 && screenWidth < 1000){
                selectedImages = content.medium;
            } else{
                selectedImages = content.full
            }

            setImages(selectedImages); 
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [content]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: autoplaySpeed
    };

    return (
        <section className="bannerS">
            {serv && <p className="title_banner">{text}</p>}
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img className="bannerP-img" src={image} alt={`Slide ${index}`} style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }} />
                    </div>
                ))}
            </Slider>
        </section>
    )
};