import React, { useState } from "react";
import './Section3.css'
import { Slider } from "../../Carousel2_test/Carousel2";

export const Section3 = ({contents3}) => {

    const team_members = contents3.members

    return (
        <section className="Section o-Section3">
            <div className="max-width-container white">
                <div className="o-Section-Cont">
                    <h2 className="o-carousel-title general-title-h2">{contents3.title}</h2>
                    <div className="o-slider"> 
                        <Slider members={team_members} cant={1} slider_type = {false}>
                            {(content) => (
                                <div className={`o-carousel-tab`}>
                                    <div className="o-carousel-cont">
                                        <div className="o-carousel-lkin">
                                            <a className="o-link-lkin" href={content.link} target="_blank">
                                                <img src="/resources/Vectores/lkin2.svg" alt="" />
                                            </a>
                                        </div>
                                        <div className="o-carousel-name">
                                            <div className="o-name-decoration">
                                                <h1 className="o-name">{content.name}</h1>
                                            </div>
                                        </div>
                                        <img className="o-img-photo" src={content.img} alt="" />
                                    </div>
                                    <div className="o-carousel-post">
                                    <h1 className="o-post-tittle">{content.post}</h1>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}