import React from "react";
import { Button } from "../../Button/Button";

import './Section4.css'
import './Section4-Movil.css'
import {NavLink} from "../../NavLink/NavLink"

export const Section4 = (prop) =>{

    const contentS4 = prop.contentS4

    const title =  contentS4.title
    const text = contentS4.desc
    const btnText=  contentS4.btn

    return(
        <section id="Section4" className="Section">
            <div className="max-width-container gray">
                <div className="S4-image">
                </div>
                <div className="line"></div>
                <div className="S4-content">
                    <h2 className="general-title-h2">{title}</h2> 
                    <p>{text}</p>
                    <NavLink className="S4-Btn" to='/KnowUs' classnl='S4-Btn'>
                        <Button textBtn={btnText} bckColorClase='' onIcon={false}/>
                    </NavLink>
                </div>
            </div>
        </section>
    )
}