import React from "react";
import './SurveyFrame.css';

export const SurveyFrame =()=>{

    return(
        <iframe  
            className="IFrame" 
            title="FormsM" 
            src="https://forms.office.com/r/CVW3ufFS5u" 
            // frameborder="0" 
            // marginwidth="0" 
            // marginheight="0" 
            // allowfullscreen 
            allowFullScreen
            webkitallowfullscreen = {"true"}
            mozallowfullscreen  = {"true"}
            msallowfullscreen = {"true"}
            loading="lazy"
            // sandbox="allow-scripts allow-modal"
            > 
            </iframe>
    );

}