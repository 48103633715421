import React from "react";
import './Section4ABUC.css'
import './Section4ABUC-Movil.css'
import { Button } from "../../Button/Button";
import { NavLink } from "../../NavLink/NavLink";


export const Section4ABUC = (prop) => {

    const contentS4 = prop.contentS4

    const title =  contentS4


    return (
        <section className="Section4ABUC-desc Section">
            <div className="max-width-container ABUC4">
                <div className="btn-servc">            
                    <NavLink className={`services-endp`} to="/services" classnl='services-endp'>
                        <Button textBtn={title} bckColorClase='greenBtn' onIcon={false}/>
                        <img className="btn-s-pancho" src="/resources/Pancho/panchoArms.svg" alt="pancho arms"/>
                    </NavLink>
                </div>
            </div>
        </section>
    )
}