import React, { useEffect, useState } from "react";
import './Footer.css'
import './Footer-Movil.css'
// import { Menu } from "../Menu/Menu";
import { Socialmedia } from "../SocialMedia/Socialmedia";
import { useWindowSize } from "../hooks/windowSize";

export const Footer = ({content, contentmenu}) =>{

    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version

    //* read windows width size and   set hooks true or false
    const screenWidth = useWindowSize();    

    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 769) {
            setisVisible(true)
        } else if (screenWidth <= 768) {
            setisVisible(false)
        }
    }, [screenWidth]);

    const year = () => {
        return new Date().getFullYear()
    }

    const sendEmail = (e) => {
        e.preventDefault();
    }

    return (
        <footer className="footer">
            <section className="content">
                <div className="max-width-container footer-internal-container" >
                    <img className='logo-footer' src="/resources/Vectores/Wadua.svg" alt="SVG Logo blanco Wadua" />
                    <p className="desc-footer">{content.section1}</p>

                    {/* <div className="section1 content-separation">
                        <div className={`S1-logo-desc ${isVisible? '': 'disable'}`}>
                            <img src="/resources/Vectores/Wadua.svg" alt="SVG Logo blanco Wadua" />
                            <p>{content.section1}</p>
                        </div>
                        
                        <div className={`iconPancho ${isVisible? 'disable': ''}`}>
                        <img src='/resources/Vectores/Button pancho.svg' alt="Svg Button Pancho" />
                        </div>
                        <div className={`Our-SM ${isVisible? 'disable': ''}`}>
                            <h4 className="socialTitle">{content.section2.our_sm}</h4>
                            <div className="SM-icons">
                            <Socialmedia/>
                            </div>
                        </div>
                    </div> */}
                    <div className="section2 content-separation">
                        <p><span><strong>DEMO</strong></span></p>
                        <h3> {content.section2.titlep1} <br/> {content.section2.titlep2}</h3>
                        <span> <a href="mailto:info@wadua.com.co">info@wadua.com.co</a></span>
                        {/* <form onSubmit={sendEmail} style={{'display':'none'}}>  {/*disable}
                            <input type="text" placeholder=" Correo electrónico..." />
                            <button><img src="/resources/Vectores/arrow.svg" alt="Svg arrow icon" /></button>
                        </form> */}
                        {/* <div className={`Our-SM ${isVisible? '': 'disable'}`}>
                            <h4 className="socialTitle">{content.section2.our_sm2}</h4>
                            <div className="SM-icons">
                            <Socialmedia/>
                            </div>
                        </div>
                        <div className={`S2-logo ${isVisible? 'disable': ''}`}>
                            <img src="/resources/Vectores/Wadua.svg" alt="SVG Logo blanco Wadua" />
                        </div> */}
                    </div>
                    <div className={`Our-SM SM-footer ${isVisible? '': 'disable'}`}>
                            {/* <h4 className="socialTitle">{content.section2.our_sm2}</h4> */}
                            <div className="SM-icons">
                                <Socialmedia/>
                            </div>
                    </div>
                    <div className="section3 content-separation">
                        {/* <div className="line"></div>
                        <img src={content.slogan} alt="SVG Slogan" /> */}
                        <p><span> <a className="a-footer" href="resources/pdf/Manual_Wadua_Analytics.pdf" target={"_blank"}>• {content.bottom.manual}</a></span></p>
                        <p><span><a className="a-footer" href="resources/pdf/policData.pdf" target={"_blank"}>• {content.bottom.politics}</a></span></p>

                    </div>
                </div>
            </section>

            <div className="bottom">
                <div className="max-width-container footer-internal-container-B">
                    {/* <span className={`bottom-text ${isVisible? '': 'disable'}`}> <a className="a-footer" href="resources/pdf/Manual_Wadua_Analytics.pdf" target={"_blank"}>{content.bottom.manual}</a></span>
                    <span className={`bottom-text ${isVisible? '': 'disable'}`}><a className="a-footer" href="resources/pdf/policData.pdf" target={"_blank"}>{content.bottom.politics}</a></span>
                    <div className={`iconPancho ${isVisible? '': 'disable'}`}>
                        <img src='/resources/Vectores/Button pancho.svg' alt="Svg Button Pancho" />
                    </div> */}
                    <span className="bottom-text">Copyright &copy; Wadua S.A.S. {content.bottom.rights}</span>
                </div>
            </div>
            {/* <img className="wadua" src="/resources/Vectores/Waduas.svg" alt="Svg Waduas" /> */}
        </footer>        
    )
}