import React, { useState } from "react";
import './Section2ABUC.css'
import './Section2ABUC-Movil.css'
import { useEffect } from "react";
import { useWindowSize } from "../../hooks/windowSize";

export const Section2ABUC = (prop) =>{

    const contentS2 = prop.contentS2

    const title =  contentS2.title
    const text = contentS2.desc
    const language = contentS2.language


    const [isVisible, setisVisible] = useState(false);  // *active objects mobile version

    //* read windows width size and   set hooks true or false
    const screenWidth = useWindowSize();    

    useEffect(() => {
        if (screenWidth < 9999 && screenWidth >= 1024) {
            setisVisible(true)
        } else if (screenWidth <= 1023) {
            setisVisible(false)
        }
    }, [screenWidth]);


    return(
        <section className="Section2ABUC-desc Section">
            <div className="max-width-container gray">
                {/* <div className="upper-slice"></div> */}
                <div className="Section2ABUC-content">
                    <h2 className="Section2ABUC-content-title general-title-h2">{title}</h2>
                    <p className="Section2ABUC-content-desc">{text}</p>
                </div>
                <div className={`Section2ABUC-img  Section2ABUC-img-${language} ${isVisible? '': 'disable'}`}>
                </div>
            </div>
        </section>
    )
}