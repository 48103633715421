import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Phenomena-Regular.otf';
import './fonts/Quicksand-VariableFont_wght.ttf';
import i18next from "i18next";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <HashRouter>
    <App />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
