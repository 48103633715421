import React, { useState } from "react";
import './Section2_carousel.css'
import { Slider } from "../../Carousel2_test/Carousel2";
import { Card } from "./Card/Card";
import { Card_base } from "./Card/Card_base";

export const Section2_carousel = ({isMovil,contents2}) => {

    const arrCont = contents2.cards

    return (
        <section className={`Section o-Section2_carousel ${isMovil? '': 'disable'}`}>
            <div className="o-Section-Cont o-Section2-Cont_serv">
                <Card_base tittle={contents2.card_base.title} text={contents2.card_base.text}/>
                <Slider members={arrCont} cant={1} slider_type={true}>
                    {(arrCont) => (
                        <div className={`o-carousel-tab o-carousel-tab-services`}>
                            <Card id={arrCont.id} tittle={arrCont.title} text={arrCont.text} text2={arrCont.text2} img={arrCont.img} color={arrCont.color}/>
                        </div>
                    )}
                </Slider>
            </div>
        </section>
    )
}