import React from "react";
import './Section1.css';



export const Section1 = ({contents1}) => {
    return (
        <section className=" o-culture Section">
            <div className="max-width-container blue">
                <div className="o-content">
                    <h1 className="o-content-title general-title-h2">{contents1.title}</h1>
                    <p className="o-content-desc">{contents1.desc}</p>
                    <h3 className="o-content-subtitle">{contents1.subtitle}</h3>
                </div>
                <div className="o-img"> 
                    <img src="/resources/Pancho/Pancho.png" alt="" />
                </div>
            </div>
        </section>
    )
}