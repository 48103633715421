import React, { useEffect } from "react";
import { Banner } from "../Components/HomePage/Banner/Banner";

import { Section1 } from "../Components/HomePage/Section1/Section1";
import { Section2 } from "../Components/HomePage/Section2/Section2";
import { Section3 } from "../Components/HomePage/Section3/Section3";
import { Section4 } from "../Components/HomePage/Section4/Section4";
import { Mision } from "../Components/HomePage/Section_Mision/Mision";
import { Contact } from "../Components/HomePage/contact/contact";
import { Experts } from "../Components/HomePage/experts/Experts"


export const MainPage = ({content}) =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <Banner content={content.banner} serv={false}/>
            <Banner content={content.services} serv={true}/>
            {/* <Section1 contentS1={content.news} isEng={false}/> */}
            <Mision contentS2={content.Mision} isEng={false}/>
            <Section3 contentS3={content.do} isEng={false}/>
            <Contact contentS4={content.contact} isEng={false}/>
            <Experts contentS3={content.experts} isEng={false}/>
            <Section2 contentS2={content.work_with_us} isEng={false}/>
            <Section4 contentS4={content.wadua_culture} isEng={false}/>
        </>
    )
}