import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Footer } from './Components/Footer/Footer';
import { Header } from './Components/Header/Header';
import { AboutusCompany } from './Pages/AboutusCompany';
import { KnowUs } from './Pages/KnowUs';
import { MainPage } from './Pages/MainPage';
import { Services } from './Pages/Services';
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import { Survey } from './Pages/Survey';


function App({t}) {
  
  // const content2 = require('./Temps/ContentEs.json');
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // }

  return (
    <div className="App">
      {/* <button onClick={() => changeLanguage('es')}>es</button>
      <button onClick={() => changeLanguage('en')}>en</button> */}
      <Header content={t('Menu')} languageContent={t('Header')}/>
      <Routes>
        <Route path='/' element={<MainPage content={t('MainPageContent')}/>}/>
        {/* <Route path='/home' element={<MainPage content={t('MainPageContent')}/>}/> */}
        <Route path='/about-us-company' element={<AboutusCompany content={t('AboutUsCompany')}/>}/>
        <Route path='/KnowUs' element={<KnowUs content={t('AboutUsComunity')}/>}/>
        <Route path='/services' element={<Services content={t('Services')}/>}/>
        <Route path='/survey' element={<Survey />}/>
        {/* <Route path='https://www.waduaverso.wadua.com.co/'/> */}
      </Routes>
      <Footer content={t('Footer')} contentmenu={t('Menu')}/>
    </div>

    
  );
}

export default withNamespaces()(App);
